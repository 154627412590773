export function trim(string: string, length: number) {
    return string.length > length ? string.substring(0, length) + "..." : string;
}

export const reorder = (list: Array<CantoObject>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export interface CantoObject {
    preview: string;
    previewUri: string;
    scheme: string;
    displayName: string;
    id: string;
    directUri: string;
    size: string;
}

export interface UiConfig {
    env: string
    extensions: string
}
