import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import UiField from "./UiField";
import UiExtension, {UiScope} from "@bloomreach/ui-extension";
import CmsField from "./CmsField";
import CmsDialog from "./CmsDialog";
import {UiDialog} from "./UiDialog";

async function render() {

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    try {
        const ui: UiScope = await UiExtension.register();

        const routing = (
            <BrowserRouter>
                <Routes>
                    <Route path="/dialog" element={<CmsDialog ui={ui}/>}/>
                    <Route path="/" element={<CmsField ui={ui}/>}/>
                </Routes>
            </BrowserRouter>
        );

        root.render(routing)

    } catch (error: any) {
        console.log(error);
        console.error('Failed to register extension:', error.message);
        console.error('- error code:', error.code);

        const items = [
            {
                "preview": "https://bloomreach.canto.com/api_binary/v1/image/vljuc0kdph01tc7i3rk514ng25/preview",
                "previewUri": "https://d3opzdukpbxlns.cloudfront.net/8b785ae0-37d3-4bd1-883b-930ade2bf3e0/fd67e6028dcc401eb0f21ee85092f045.240.jpg?response-content-disposition=inline%3B%20filename%2A%3DUTF-8%27%271673272763000.jpg&response-content-type=image%2Fjpeg&Expires=1673740800&Signature=Xth3HclWCj5TuRtEndKD0BSnnqGgJT~fL0t-fwsuWPn-QjNGKdJ4Ps1Zv4oHdyxQhzyodq5Zgbw~BWi6a0FWsrquVl6qKuVB41Hn3y4r3zG2NqgdcIT7BM1WhFLzO33A2WF7DQmjrlEVJsYmYBR6aEcUwuoNj4qGapjY-lDBNiMY3vwlNyqJeXTIiItQ2jsFhNSzSP-t~cE~85ZUV2k7gmS4tLNF9z0YwreKShWUMOyEB7~5UyhVsyAUgRh701wDo-vrEGwNpVKsIzYSPhe1m6C0YuvkGBIdQtr9~sw8VNffVY~vnUX031S4Njkn2~xL48wGA6zMiWo2t1tw~VS4qQ__&Key-Pair-Id=APKAI33AGAEAYCXFBDTA",
                "scheme": "image",
                "displayName": "Canto logo blue.png",
                "id": "vljuc0kdph01tc7i3rk514ng25",
                "directUri": "https://d3opzdukpbxlns.cloudfront.net/8b785ae0-37d3-4bd1-883b-930ade2bf3e0/fd67e6028dcc401eb0f21ee85092f045.keywords_tags.73491e950daa92ef8169ad7f19a0fe57?response-content-disposition=attachment%3B%20filename%2A%3DUTF-8%27%27Canto%2520logo%2520blue.png&Expires=1673740800&Signature=IkLjmSZ8VpoCuaytj5bs8e7nxripiprEJOkysprWvRGeOn~G-5lIv7eIhBp~5BzpuHd3~uKfMyYer3FqZoHPBNZ07o1jI7gl3xvwodir-oTl~xImhi6y9sZfImmnYs4VhtUnfO2Pg1v3dnhb7q2BeYp~MzsxvpQgEXg4dxs4LLxJz9D2V5DDWmMEof~dC2i-tEye6UF~e~3VjTrW3SQlLGYMEMOGoE9cNlRUMUCo1IwEnpejsFaT0ZMHJ8BtrR4DNDpV67JYHuyc4hftKKLdS0V8hOhAy51VqbfEAPpfneWaGhfI7Kp4b42FaE7HyJzjAQDoSXwbeuFvTgCkE3HjcQ__&Key-Pair-Id=APKAI33AGAEAYCXFBDTA",
                "size": "12967"
            }
        ];

        const routing = (
            <BrowserRouter>
                <Routes>
                    <Route path="/dialog" element={<UiDialog onOk={items => console.log('onOk', items)}
                                                             extensions={"svg"}
                                                             items={[]} env={"canto.com"}/>}/>
                    <Route path="/"
                           element={<UiField items={items} onChange={items => console.log('onChange', items)}
                                             editMode={true}
                                             onOpenDialog={() => window.open("/dialog")}/>}/>
                </Routes>
            </BrowserRouter>
        );

        root.render(routing)

    }
}

render();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
