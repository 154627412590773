import React from "react";
import {CantoObject} from "./utils";
import {useExternalScript} from "./useExternalScript";

interface DialogProperties {
    onOk: (items: Array<CantoObject>) => void
    items: Array<CantoObject>
    env: string
    extensions?: string | undefined
}

export function UiDialog({onOk, env, extensions}: DialogProperties): JSX.Element | null {

    // @ts-ignore
    window.environment = env;
    // @ts-ignore
    window.extensions = extensions;
    // @ts-ignore
    window.select = onOk;
    useExternalScript('/cantoUniversalConnector.js')


    return <>
    </>
}


